import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`Pre žiakov základných a stredných škôl ponúkame možnosť lyžiarskeho kurzu u nás v Studničkách.`}</p>
      <p>{`Pripravili sme pre vás 2 zvýhodnené balíčky:`}</p>
      <h2>{`Lyžiarske kurzy `}<br />{` s KOMPLETNÝM BALÍKOM SLUŽIEB`}</h2>
      <ul>
        <li parentName="ul">{`celodenný skipass,`}</li>
        <li parentName="ul">{`výbornú domácu stravu formou plnej penzie (raňajky, obedy, večere),`}</li>
        <li parentName="ul">{`zabezpečenie pitného režimu,`}</li>
        <li parentName="ul">{`ubytovanie na 4 noci (pondelok – piatok) priamo pri svahu v Hoteli Studničky.`}</li>
        <li parentName="ul">{`Minimálny počet ubytovaných je 40 osôb.`}</li>
      </ul>
      <h2>{`Lyžiarske kurzy `}<br />{` so STRAVOVACÍM BALÍKOM pre dochádzajúcich`}</h2>
      <ul>
        <li parentName="ul">{`celodenný skipass,`}</li>
        <li parentName="ul">{`zabezpečenie pitného režimu,`}</li>
        <li parentName="ul">{`bohaté desiatové a obedové menu.`}</li>
      </ul>
      <h2>{`Bonusy pre vás`}</h2>
      <p>{`Školský výlet u nás sa skutočne oplatí! Okrem skvelých zliav vás poteší aj:`}</p>
      <ul>
        <li parentName="ul">{`chutná domáca kuchyňa a možnosť bezlepkovej stravy,`}</li>
        <li parentName="ul">{`uschovanie lyžiarskej výstroje priamo v hoteli - vlastná lyžiareň,`}</li>
        <li parentName="ul">{`celodenný skipass v cene zakúpeného balíka,`}</li>
        <li parentName="ul">{`4 lyžiarske vleky pre začiatočníkov aj pokročilých (celková dĺžka tratí je 1400 m),`}</li>
        <li parentName="ul">{`technicky zasnežené a denne upravované zjazdovky,`}</li>
        <li parentName="ul">{`požičovňa kompletnej lyžiarskej výstroje a servis lyží (za príplatok),`}</li>
        <li parentName="ul">{`bezplatné parkovanie.`}</li>
      </ul>
      <p>{`V prípade záujmu alebo akýchkoľvek otázok nás neváhajte `}<a parentName="p" {...{
          "href": "/kontakt/"
        }}>{`kontaktovať`}</a>{`.`}</p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      